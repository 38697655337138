<script setup lang="ts">
    import { useI18n } from 'vue-i18n';
    import Button from 'primevue/button';
    import type { CtxProduct, CtxProductVariant } from '@containex/portal-backend-api-client';
    import { usePriceFormatter } from '@/composables/formatPrice';
    import { computed, watch } from 'vue';
    import ProductAvailability from '@/components/ProductAvailability.vue';
    import DepotLocationWithIcon from '@/components/DepotLocationWithIcon.vue';
    import PriceInformation from '@/components/PriceInformation.vue';
    import { Availability } from '@containex/portal-backend-dto';
    import { Navigation } from '@/router/navigation';
    import { getAvailabilityForVariantBasedOnCart } from '@/util/variantAvailability';
    import { useRouter } from 'vue-router';
    import { useOrderInfoSidebarAction } from '@/composables/order-info-sidebar';
    import { useCartAction, useCartQuery } from '@/composables/cart';
    import { buttonFontWeight500 } from '@/styling/components/button';

    const props = defineProps<{
        product: CtxProduct;
        variant?: CtxProductVariant;
        currentRegionId?: string;
        postalCode?: string;
    }>();

    const emits = defineEmits<{
        'add-to-cart': [variantId: string];
    }>();

    const { t } = useI18n();
    const { formatPrice } = usePriceFormatter();
    const router = useRouter();
    const orderInfoSidebarAction = useOrderInfoSidebarAction();
    const { cart } = useCartQuery();
    const cartAction = useCartAction();

    const currentVariantAvailability = computed(() => {
        if (props.variant != null) {
            return getAvailabilityForVariantBasedOnCart(props.variant, cart.value, true);
        }
        return Availability.NotAvailable;
    });

    const currentVariantCompletionDate = computed(() => props.variant?.depotQuantity.completion_date);
    const currentVariantOnDemandCompletionDate = computed(() => props.variant?.depotQuantity.on_demand_completion_date);

    let isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));

    watch(cart, () => {
        isVariantAvailableForCart = computed(() => cartAction.isVariantAddableToCart(props.variant));
    });

    function addToCart(variantId: string): void {
        if (variantId === '') {
            return;
        }

        emits('add-to-cart', variantId);
    }
</script>
<template>
    <div class="product-details-container">
        <h2 class="text-2xl-bold-line-height-auto">
            {{ product.title }}
        </h2>
        <div class="product-detail-location-availability">
            <div v-if="variant?.depotQuantity.depot.type != null">
                <DepotLocationWithIcon
                    :depot-type="variant?.depotQuantity.depot.type"
                    :depot-location="variant?.depotQuantity.depot.address.city"
                />
            </div>
            <div v-if="currentVariantAvailability" class="product-detail-availability">
                <ProductAvailability
                    :product-available="currentVariantAvailability"
                    :completion-date="currentVariantCompletionDate"
                    :on-demand-completion-date="currentVariantOnDemandCompletionDate"
                />
            </div>
        </div>
        <template v-if="currentVariantAvailability != Availability.NotAvailable">
            <div
                v-if="currentRegionId != null && postalCode != null && (variant?.original_price ?? 0) > 0"
                class="product-details"
            >
                <div class="text-2xl-bold-line-height-auto">
                    {{ formatPrice(variant?.original_price ?? 0) }}
                </div>
                <div v-if="variant?.depotQuantity.depot.type != null">
                    <PriceInformation
                        class="text-sm-regular-line-height-auto"
                        :depot-type="variant?.depotQuantity.depot.type"
                        :depot-location="variant?.depotQuantity.depot.address.city"
                    />
                </div>
                <span class="text-sm-regular-line-height-auto exclusive-tax-font-style">{{
                    t('PRODUCT.EXCLUSIVE_TAX')
                }}</span>
            </div>
            <div v-else-if="currentRegionId != null && postalCode != null" class="text-2xl-bold-line-height-auto">
                {{ t('PRODUCT.PRICE_ON_REQUEST') }}
            </div>
            <div v-if="currentRegionId != null && postalCode != null && (variant?.original_price ?? 0) > 0">
                <Button
                    icon="pi pi-cart-plus"
                    icon-pos="left"
                    :label="t('CART.ADD_TO_CART')"
                    :disabled="!isVariantAvailableForCart"
                    class="cta-button"
                    :dt="buttonFontWeight500"
                    data-testid="product-detail-view-add-to-cart-button"
                    @click="addToCart(variant?.id ?? '')"
                />
            </div>
            <div v-else-if="currentRegionId == null || postalCode == null" class="show-price-container">
                <div class="show-price-container-title text-xl-bold-line-height-auto">
                    {{ t('PRODUCT.SHOW_PRICE_TITLE_SALES') }}
                </div>
                <div>{{ t('PRODUCT.SHOW_PRICE_DESCRIPTION_SALES') }}</div>
                <Button
                    :label="t('PRODUCT.SHOW_PRICE_BUTTON')"
                    class="cta-button cta-button__fit-content"
                    data-testid="product-detail-view-show-price-button"
                    :dt="buttonFontWeight500"
                    @click.prevent="orderInfoSidebarAction.setIsVisible(true)"
                />
            </div>
        </template>
        <div v-else class="product-info-not-available-container">
            <div>{{ t('PRODUCT.INFO_PRODUCT_NOT_AVAILABLE') }}</div>
            <Button
                :label="t('PRODUCT.VIEW_PRODUCT_LIST')"
                class="cta-button cta-button__fit-content"
                :dt="buttonFontWeight500"
                @click.prevent="router.push({ name: Navigation.SalesProductList })"
            />
        </div>
    </div>
</template>
<style scoped lang="scss">
    @use 'src/styling/main';

    .product-details-container {
        display: flex;
        flex-direction: column;
        row-gap: main.$spacing-6;
    }

    .product-detail-location-availability {
        margin-left: main.$spacing-3;
        margin-top: main.$spacing-2;
    }

    .product-detail-availability {
        margin-top: main.$spacing-2;
    }

    .product-details {
        display: flex;
        flex-direction: column;
        justify-content: left;
        align-items: flex-start;
    }

    .exclusive-tax-font-style {
        color: main.$color-secondary;
        margin-top: main.$spacing-2;
    }

    .cta-button {
        width: 100%;

        &__fit-content {
            width: fit-content;
        }
    }

    @include main.for-breakpoint-sm {
        .cta-button {
            width: unset;
        }
    }

    .show-price-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        background-color: main.$color-background-lightblue;
        padding: main.$spacing-5;
        border-radius: 4px;

        &-title {
            color: main.$color-primary-darker;
        }
    }

    .product-info-not-available-container {
        background-color: main.$color-background-bluegray;
        display: flex;
        row-gap: main.$spacing-5;
        flex-direction: column;
        padding: main.$spacing-5;
        margin-bottom: main.$spacing-5;
        border-radius: 5px;
    }
</style>
